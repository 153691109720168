import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import VideoHeroCollection from '../../promo/collections/VideoHeroCollection';
import { promoShape } from '../../promo/shapes';
import MetaProperties from '../../generic/MetaProperties';

export const RecentProgrammesTab = ({ programmes }) => (
  <>
    <MetaProperties
      title="Programmes"
      description="Quickly find recipes from your favourite current BBC programmes, or browse the archive of BBC recipes from shows gone by."
    />
    {programmes.map(programme => (
      <VideoHeroCollection
        id={`programmes-${programme.id}`}
        pid={programme.id}
        key={programme.id}
        title={programme.title}
        promos={programme.promos}
        date={programme.broadcastDate}
      />
    ))}
  </>
);

RecentProgrammesTab.propTypes = {
  programmes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      promos: PropTypes.arrayOf(PropTypes.shape(promoShape)),
      broadcastDate: PropTypes.string.isRequired,
    })
  ),
};

RecentProgrammesTab.defaultProps = {
  programmes: [],
};

export default connect(({ programmesIndexReducer }) => ({
  programmes: programmesIndexReducer.programmes,
}))(RecentProgrammesTab);
