import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Collection from '../Collection';
import Promo from '../Promo';
import { promoShape } from '../shapes';

const StandardCollection12Promos = ({
  breakpoints,
  containerClassName,
  description,
  extraClasses,
  id,
  maxCollectionSize,
  promos,
  promoSynopsisClassName,
  promoTitleClassName,
  title,
  TitleElement,
}) => (
  <Collection
    containerClassName={containerClassName}
    collectionClassName="standard-12-promos"
    description={description}
    id={id}
    TitleElement={TitleElement}
    title={title}
  >
    {promos
      .slice(0, maxCollectionSize)
      .map(
        ({
          duration,
          episodeNumber,
          id: promoId,
          imageId,
          label,
          playIconClass,
          seriesNumber,
          synopsis,
          subtitle,
          title: promoTitle,
          type,
          url,
        }) => (
          <div key={promoId} className={classNames('gel-layout__item', breakpoints)}>
            <Promo
              extraClasses={{
                content: 'gel-1/1',
                image: 'gel-1/1',
                ...extraClasses,
              }}
              collectionId={id}
              collectionTitle={title}
              duration={duration}
              episodeNumber={episodeNumber}
              id={promoId}
              imageId={imageId}
              label={label}
              playIconClass={playIconClass}
              seriesNumber={seriesNumber}
              synopsis={synopsis}
              synopsisClassName={promoSynopsisClassName}
              subtitle={subtitle}
              title={promoTitle}
              titleClassName={promoTitleClassName}
              type={type}
              url={url}
            />
          </div>
        )
      )}
  </Collection>
);

StandardCollection12Promos.propTypes = {
  breakpoints: PropTypes.string,
  containerClassName: PropTypes.string,
  description: PropTypes.string,
  extraClasses: PropTypes.shape({
    content: PropTypes.string,
    image: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  maxCollectionSize: PropTypes.number,
  promos: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  promoSynopsisClassName: PropTypes.string,
  promoTitleClassName: PropTypes.string,
  title: PropTypes.string,
  TitleElement: PropTypes.node,
};

StandardCollection12Promos.defaultProps = {
  breakpoints: 'gel-1/2 gel-1/3@m gel-1/4@xl',
  containerClassName: undefined,
  description: '',
  extraClasses: {},
  maxCollectionSize: 12,
  promoSynopsisClassName: undefined,
  promoTitleClassName: undefined,
  title: '',
  TitleElement: 'h2',
};

export default StandardCollection12Promos;
