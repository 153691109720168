import React from 'react';
import PropTypes from 'prop-types';

if (process.env.CLIENT) {
  require('./PaginationSummary.scss'); // eslint-disable-line global-require
}

const PaginationSummary = ({ children, page, pageSize, totalCount }) => {
  const pageStart = (page - 1) * pageSize + 1;
  const pageEnd = Math.min(pageStart + pageSize - 1, totalCount);

  return (
    <div className="pagination-summary gel-wrap">
      <div className="gel-layout">
        <div className="gel-layout__item">
          <span className="gel-pica">
            {`Showing ${pageStart} - ${pageEnd} of `}
            <b className="gel-pica-bold">{totalCount}</b> {children}
          </span>
        </div>
      </div>
    </div>
  );
};

PaginationSummary.defaultProps = {
  children: 'items',
  pageSize: 24,
};

PaginationSummary.propTypes = {
  children: PropTypes.node,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number.isRequired,
};

export default PaginationSummary;
