import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StandardHeroCollection from './StandardHeroCollection';
import { CollectionTitleLink, CollectionDate } from '../Collection';
import { promoShape } from '../shapes';

if (process.env.CLIENT) {
  require('./VideoHeroCollection.scss'); // eslint-disable-line global-require
}

const VideoHeroCollection = ({ id, pid, title, promos, TitleElement, date }) => (
  <div
    id={id}
    className={classNames(
      'gel-wrap',
      'promo-collection__container',
      'promo-collection__container--video'
    )}
  >
    <CollectionTitleLink href={`/food/programmes/${pid}`} TitleElement={TitleElement}>
      {title}
    </CollectionTitleLink>
    {date && <CollectionDate>{date}</CollectionDate>}
    <StandardHeroCollection id={id} title={title} promos={promos} />
  </div>
);

VideoHeroCollection.propTypes = {
  id: PropTypes.string.isRequired,
  pid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  promos: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  TitleElement: PropTypes.node,
  date: PropTypes.string,
};

VideoHeroCollection.defaultProps = {
  TitleElement: 'h2',
  date: '',
};

export default VideoHeroCollection;
