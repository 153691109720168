import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import PageHeader from '../generic/PageHeader';
import RecentProgrammesTab from './recent/RecentProgrammesTab';
import AtozProgrammesTab from './atoz/AtozProgrammesTab';
import TabBar from '../generic/tabBar/TabBar';
import Tab from '../generic/tabBar/Tab';

if (process.env.CLIENT) {
  require('./Page.scss'); // eslint-disable-line global-require
}

const Page = ({ match }) => (
  <div className="programmes-page">
    <PageHeader className="programmes__title" title="Programmes" />
    <TabBar>
      <Route exact path={match.path}>
        {({ match: selected }) => (
          <Tab href="/food/programmes" title="Recent programmes" selected={!!selected}>
            Recent
          </Tab>
        )}
      </Route>
      <Route exact path={`${match.path}/:letter/:page`}>
        {({ match: selected }) => (
          <Tab href="/food/programmes/a-z/a/1" title="All programmes" selected={!!selected}>
            All
          </Tab>
        )}
      </Route>
    </TabBar>
    <Route exact path={match.path} component={RecentProgrammesTab} />
    <Route exact path={`${match.path}/:letter/:page`} component={AtozProgrammesTab} />
  </div>
);

Page.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default Page;
