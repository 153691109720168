import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

if (process.env.CLIENT) {
  require('./Tab.scss'); // eslint-disable-line global-require
}

const Tab = ({ tag: Tag, selected, children, ...props }) => (
  <Tag
    className={classNames('tab-bar__tab gel-pica', {
      'tab-bar__tab--selected gel-pica-bold': selected,
    })}
    {...props}
  >
    <span>{children}</span>
  </Tag>
);

Tab.propTypes = {
  tag: PropTypes.elementType,
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
};

Tab.defaultProps = {
  tag: 'a',
  selected: false,
};

export default Tab;
