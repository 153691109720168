import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

if (process.env.CLIENT) {
  require('./AzKeyboard.scss'); // eslint-disable-line global-require
}
const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

const ListItem = ({ active, baseUrl, letter, selected, numeric, anchor }) => (
  <li
    className={classnames(
      'az-keyboard__list-item',
      'gel-layout__item',
      'gel-1/12@l',
      'gel-2/12@s',
      numeric ? 'gel-1/3' : 'gel-2/12'
    )}
  >
    {active ? (
      <a
        className={classnames(
          'az-keyboard__link',
          {
            'az-keyboard__link--selected': selected,
          },
          'gel-pica-bold'
        )}
        href={`${baseUrl}/a-z/${letter}/1#${anchor}`}
      >
        {letter.toUpperCase()}
      </a>
    ) : (
      <span
        className={classnames('az-keyboard__link', 'az-keyboard__link--disabled', 'gel-pica-bold')}
      >
        {letter.toUpperCase()}
      </span>
    )}
  </li>
);

ListItem.defaultProps = {
  active: true,
  selected: false,
  numeric: false,
};

ListItem.propTypes = {
  active: PropTypes.bool,
  baseUrl: PropTypes.string.isRequired,
  letter: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  numeric: PropTypes.bool,
  anchor: PropTypes.string.isRequired,
};

const AzKeyboard = ({ baseUrl, azCounts, selected, anchor }) => (
  <div className={classnames('az-keyboard', 'gel-wrap')}>
    <div className="gel-layout">
      <ul className="az-keyboard__list">
        {alphabet.map(letter => (
          <ListItem
            baseUrl={baseUrl}
            letter={letter}
            selected={selected === letter}
            active={azCounts[letter] > 0}
            key={letter}
            anchor={anchor}
          />
        ))}
        {azCounts['0-9'] > 0 && (
          <ListItem
            baseUrl={baseUrl}
            letter="0-9"
            selected={selected === '0-9'}
            numeric
            anchor={anchor}
          />
        )}
      </ul>
    </div>
  </div>
);

AzKeyboard.defaultProps = {
  azCounts: {},
};

AzKeyboard.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  azCounts: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  anchor: PropTypes.string.isRequired,
};

export default AzKeyboard;
