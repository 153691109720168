/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { PreviousIcon, NextIcon } from '../Icons';

if (process.env.CLIENT) {
  require('./Pagination.scss'); // eslint-disable-line global-require
}

const MAX_PAGE_BUTTONS = 20;

const ListItem = ({ active, children, clientSide, ellipsis, link, priority, selected }) => (
  <li
    className={classnames('pagination__list-item', `pagination__priority--${priority}`, {
      pagination__ellipsis: ellipsis,
    })}
  >
    {active ? (
      clientSide ? (
        <Link
          className={classnames('pagination__link', 'gel-pica-bold', {
            'pagination__link--selected': selected,
          })}
          to={link}
        >
          {children}
        </Link>
      ) : (
        <a
          className={classnames('pagination__link', 'gel-pica-bold', {
            'pagination__link--selected': selected,
          })}
          href={link}
        >
          {children}
        </a>
      )
    ) : (
      <span
        className={classnames('pagination__link', 'pagination__link--disabled', 'gel-pica-bold')}
      >
        {children}
      </span>
    )}
  </li>
);

ListItem.defaultProps = {
  active: true,
  clientSide: false,
  ellipsis: false,
  link: '',
  priority: 0,
  selected: false,
};

ListItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  clientSide: PropTypes.bool,
  ellipsis: PropTypes.bool,
  link: PropTypes.string,
  priority: PropTypes.number,
  selected: PropTypes.bool,
};

const assignPriority = index => {
  if (index < 3) return 0;
  if (index < 4) return 1;
  if (index < 8) return 2;
  if (index < 11) return 3;
  return 4;
};

const getPageNumbers = (currentPage, totalPages) => {
  const surroundingPages = [...Array(MAX_PAGE_BUTTONS).keys()].map(
    index => currentPage + (index % 2 ? -1 : 1) * Math.ceil((index + 1) / 2)
  );

  return Array.from(new Set([currentPage, 1, totalPages, ...surroundingPages]))
    .filter(button => button > 0 && button <= totalPages)
    .map((button, index) => [button, assignPriority(index)])
    .sort((a, b) => a[0] - b[0]);
};

const Pagination = ({ buildUrl, clientSide, page, pages }) => {
  const pageList = getPageNumbers(page, pages);

  return (
    <div className={classnames('pagination', 'gel-wrap')}>
      {pages > 1 && (
        <ul className="pagination__list">
          <ListItem active={page > 1} clientSide={clientSide} link={buildUrl(page - 1)}>
            <PreviousIcon />
          </ListItem>
          {pageList.map(([pageNo, priority], index) => (
            <ListItem
              clientSide={clientSide}
              link={buildUrl(pageNo)}
              selected={page === pageNo}
              priority={priority}
              ellipsis={index === 1 || index === pageList.length - 2}
              key={`page-${pageNo}`}
            >
              {pageNo}
            </ListItem>
          ))}
          <ListItem active={page < pages} clientSide={clientSide} link={buildUrl(page + 1)}>
            <NextIcon />
          </ListItem>
        </ul>
      )}
    </div>
  );
};

Pagination.propTypes = {
  buildUrl: PropTypes.func.isRequired,
  clientSide: PropTypes.bool,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
};

Pagination.defaultProps = {
  clientSide: false,
};

export { Pagination as default, ListItem };
