import React from 'react';
import PropTypes from 'prop-types';

if (process.env.CLIENT) {
  require('./TabBar.scss'); // eslint-disable-line global-require
}

const TabBar = ({ children }) => (
  <div className="gel-wrap tab-bar__wrap">
    <div className="gel-layout">
      <div className="gel-layout__item">
        <div className="tab-bar">{children}</div>
      </div>
    </div>
  </div>
);

TabBar.propTypes = {
  children: PropTypes.node,
};

TabBar.defaultProps = {
  children: undefined,
};

export default TabBar;
