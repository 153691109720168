import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CollectionPromos } from '../Collection';
import Promo from '../Promo';
import { promoShape } from '../shapes';

if (process.env.CLIENT) {
  require('./StandardHeroCollection.scss'); // eslint-disable-line global-require
}

const getBreakpoints = index =>
  index === 0 ? 'gel-1/1 gel-2/3@m gel-1/2@l' : 'gel-1/2 gel-1/3@m gel-1/4@l';

const StandardHeroCollection = ({ id, title, promos, maxCollectionSize }) => (
  <CollectionPromos className={classNames('promo-collection', 'standard-hero')}>
    {promos
      .slice(0, maxCollectionSize)
      .map(
        (
          {
            duration,
            episodeNumber,
            id: promoId,
            imageId,
            playIconClass,
            title: promoTitle,
            seriesNumber,
            synopsis,
            subtitle,
            type,
            url,
          },
          i
        ) => (
          <div key={promoId} className={classNames('gel-layout__item', getBreakpoints(i))}>
            <Promo
              collectionId={id}
              collectionTitle={title}
              duration={duration}
              episodeNumber={episodeNumber}
              id={promoId}
              imageId={imageId}
              playIconClass={playIconClass}
              title={promoTitle}
              titleClassName={i === 0 ? 'gel-double-pica-bold' : undefined}
              seriesNumber={seriesNumber}
              synopsis={i === 0 ? synopsis : undefined}
              subtitle={subtitle}
              type={type}
              url={url}
            />
          </div>
        )
      )}
  </CollectionPromos>
);

StandardHeroCollection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  promos: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  maxCollectionSize: PropTypes.number,
};

StandardHeroCollection.defaultProps = {
  id: undefined,
  title: undefined,
  maxCollectionSize: 7,
};

export default StandardHeroCollection;
