import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import StandardCollection12Promos from '../../promo/collections/StandardCollection12Promos';
import AzKeyboard from '../../generic/aToZ/AzKeyboard';
import Pagination from '../../generic/pagination/Pagination';
import PaginationSummary from '../../generic/pagination/PaginationSummary';
import { promoShape } from '../../promo/shapes';
import MetaProperties from '../../generic/MetaProperties';

export const AtozProgrammesTab = ({ azCounts, baseURL, letter, page, pages, promos }) => (
  <>
    <MetaProperties
      title={`Programmes | ${letter.toUpperCase()}`}
      description={`Find recipes from your favourite programmes on BBC Food. This page is an alphabetical listing of programmes that start with ${letter}.`}
      canonical={`${baseURL}/food/programmes`}
    />
    <Helmet>
      {page > 1 && (
        <link rel="prev" href={`${baseURL}/food/programmes/a-z/${letter}/${page - 1}`} />
      )}
      {page < pages && (
        <link rel="next" href={`${baseURL}/food/programmes/a-z/${letter}/${page + 1}`} />
      )}
    </Helmet>
    <AzKeyboard baseUrl="/food/programmes" selected={letter} azCounts={azCounts} anchor="" />
    <PaginationSummary page={page} totalCount={azCounts[letter]}>
      programmes
    </PaginationSummary>
    {promos && (
      <StandardCollection12Promos
        id={`az-programmes-${letter}-recipes`}
        promos={promos}
        maxCollectionSize={24}
      />
    )}
    <Pagination
      page={page}
      pages={Math.ceil(azCounts[letter] / 24)}
      buildUrl={p => `/food/programmes/a-z/${letter}/${p}`}
    />
  </>
);

AtozProgrammesTab.propTypes = {
  azCounts: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  baseURL: PropTypes.string.isRequired,
  letter: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  promos: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
};

export default connect(({ pageReducer, programmesAzPageReducer }) => ({
  azCounts: programmesAzPageReducer.azCounts,
  baseURL: pageReducer.baseURL,
  letter: programmesAzPageReducer.letter,
  page: programmesAzPageReducer.page,
  pages: programmesAzPageReducer.pages,
  promos: programmesAzPageReducer.promos,
}))(AtozProgrammesTab);
